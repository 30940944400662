import {
  type Language,
  type Property,
  Currency,
  PropertyKind,
  PropertyMarketingType,
  PropertyType,
} from '@ev/search-modules-api';
import { type ParseKeys } from 'i18next';

import { Country } from '@/core/enums/country';
import {
  formatPriceWithDecimals,
  formatPriceWithoutDecimals,
} from '@/core/utils/priceFormatters';

type PriceDetails = {
  labelTranslationKey: ParseKeys<'common'>;
  utilitiesValue?: string;
  value: string;
  rentNetValue?: string;
};

export const getCurrentPriceOrRentProps = (
  property: Partial<Property>,
  language: Language,
  currency?: Currency
): PriceDetails => {
  const currencyUppercase = currency
    ? currency.toUpperCase()
    : property.baseCurrency?.toUpperCase() || Currency.EUR;
  const isDevelopmentProperty =
    property.objectType === PropertyType.group ||
    property.kind === PropertyKind.group;

  if (isDevelopmentProperty) {
    return {
      value: formatPriceWithoutDecimals(
        property.project?.aggregate?.prices?.[currencyUppercase]
          ?.minSalesPrice || 0,
        language,
        currencyUppercase
      ),
      labelTranslationKey: 'search.price.label.development.price',
    };
  }

  if (property.marketingType === PropertyMarketingType.sale) {
    return {
      value: formatPriceWithoutDecimals(
        property.prices?.[currencyUppercase]?.salesPrice ?? 0,
        language,
        currencyUppercase
      ),
      labelTranslationKey: 'search.price.label.salesPrice',
    };
  }

  const rentNet = formatPriceWithoutDecimals(
    property.prices?.[currencyUppercase]?.rentNet ?? 0,
    language,
    currencyUppercase
  );
  const rentTotal = formatPriceWithoutDecimals(
    property.prices?.[currencyUppercase]?.rentTotal ?? 0,
    language,
    currencyUppercase
  );
  const utilitiesTotal = formatPriceWithDecimals(
    property.prices?.[currencyUppercase]?.rentUtilities ?? 0,
    language,
    currencyUppercase
  );
  const rentNetPerSquareMeter = formatPriceWithDecimals(
    property.prices?.[currencyUppercase]?.rentNetPerSquareMeter ?? 0,
    language,
    currencyUppercase
  );

  const rentalPropertyProps: {
    value: string;
    rentNetValue: string;
    utilitiesValue: string;
    labelTranslationKey: ParseKeys<'common'>;
  } = {
    value: rentTotal,
    rentNetValue: rentNet,
    utilitiesValue: utilitiesTotal,
    labelTranslationKey: 'search.price.label.rentTotal',
  };

  if (
    property.countryAlpha2 === Country.DE &&
    rentTotal &&
    rentNet &&
    utilitiesTotal
  ) {
    rentalPropertyProps.labelTranslationKey = 'search.price.label.rentTotal';
  }

  if (property.countryAlpha2 === Country.DE && !utilitiesTotal && !rentTotal) {
    rentalPropertyProps.labelTranslationKey = 'search.price.label.rentNet';
  }

  // other countries, this was originally here before the Germany-specific work was started but these translations need to be checked again
  if (!rentTotal && rentNetPerSquareMeter) {
    rentalPropertyProps.value = rentNetPerSquareMeter;
    rentalPropertyProps.rentNetValue = '';
    rentalPropertyProps.labelTranslationKey =
      'search.price.label.rentNetPerSquareMeter';
  }

  if (!rentTotal && !rentNetPerSquareMeter && rentNet) {
    rentalPropertyProps.value = rentNet;
    rentalPropertyProps.rentNetValue = '';
    rentalPropertyProps.labelTranslationKey = 'search.price.label.rentNet';
  }

  if (
    property.priceOnRequest ||
    (!rentNet && !rentTotal && !rentNetPerSquareMeter)
  ) {
    rentalPropertyProps.value = '';
    rentalPropertyProps.labelTranslationKey = 'search.price.label.rent';
  }

  return rentalPropertyProps;
};
