import { AB_TESTS } from '@/core/const/ab-tests';
import { type ABTestVariation } from '@/core/types/tracking.types';

export const getCookie = (keyName: string): string => {
  try {
    const regex = new RegExp(
      `(?:(?:^|.*;\\s*)${keyName}\\s*\\=\\s*([^;]*).*$)|^.*$`
    );
    return decodeURIComponent(document.cookie.replace(regex, '$1'));
  } catch {
    return '';
  }
};

export const setLanguageCookie = (language: string) => {
  // This cookie is used by Digital Package to determine the language of a page
  // https://engelvoelkers.atlassian.net/browse/SEL-637
  if (document.cookie) {
    document.cookie = `_icl_current_language=${language}; path=/`;
  }
};

export const setABTestCookie = (variation: ABTestVariation) => {
  return `${AB_TESTS.contactForm.cookie.name}=${variation}; Path=/; Max-Age=${AB_TESTS.contactForm.cookie.duration}; Secure; SameSite=lax;`;
};
