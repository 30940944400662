/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  disableAnonymizedTracking,
  enableAnonymizedTracking,
} from '@ev/snowplow-library';

export function hasUserAcceptedTracking(): boolean {
  if (!window.Didomi) {
    return false;
  }

  const userCookieStatus = window.Didomi?.getUserStatus?.();
  const legitimateInterestConsents =
    userCookieStatus?.vendors?.consent?.enabled;

  if (
    legitimateInterestConsents &&
    legitimateInterestConsents.indexOf('c:engeland-UPm6kfxh') > -1
  ) {
    return true;
  }

  return false;
}

// check if snowplow is initialised and controls the tracking type
function checkAndUpdateTracking(): void {
  const hasUserAcceptedAgreement = hasUserAcceptedTracking();

  if (hasUserAcceptedAgreement) {
    disableAnonymizedTracking();
    return;
  }
  enableAnonymizedTracking();
}

// Didomi event listener that listens for the didomi consent
export function attachConsentListeners(): void {
  window.didomiEventListeners = window.didomiEventListeners || [];
  window.didomiOnReady = window.didomiOnReady || [];

  window.didomiOnReady.push(function () {
    checkAndUpdateTracking();
  });

  window.didomiEventListeners.push(
    {
      event: 'notice.clickagree',
      listener: function () {
        checkAndUpdateTracking();
      },
    },
    {
      event: 'notice.changed',
      listener: function () {
        checkAndUpdateTracking();
      },
    }
  );
}
