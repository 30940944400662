import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const CloseIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="m12 12.706 6.296 6.293.707-.707L12.707 12l6.296-6.293-.707-.707L12 11.292 5.703 5l-.707.707 6.296 6.293-6.296 6.293.707.707L12 12.706Z"
      />
    </SvgIcon>
  );
};
