import { useEffect, useState } from 'react';
import { type AlertVariants } from 'spearhead/components/elements/Alert';

import { Copy } from '@/components/elements';

import { StyledLink, StyledSnackbar } from './Snackbar.styled';

type SnackbarAction = {
  label: string;
  onClick: () => void;
};

export type SnackbarProps = {
  label: string;
  title?: string;
  variant?: AlertVariants;
  autoHideDuration?: number;
  onClose: () => void;
  action?: SnackbarAction;
};

export const Snackbar = ({
  label,
  title,
  variant = 'success',
  autoHideDuration = 5000,
  onClose,
  action,
}: SnackbarProps) => {
  const [isFadingOut, setIsFadingOut] = useState(false);

  const fadeOutSnackbar = () => {
    setIsFadingOut(true);
    setTimeout(onClose, 200);
  };

  useEffect(() => {
    const timer = setTimeout(fadeOutSnackbar, autoHideDuration);
    return () => clearTimeout(timer);
  }, []);

  const actionClicked = () => {
    action?.onClick();
    fadeOutSnackbar();
  };

  return (
    <StyledSnackbar
      fadeOut={isFadingOut}
      // key is needed to trigger animation again
      key={isFadingOut ? 'fadeOut' : 'fadeIn'}
      variant={variant}
      title={title}
      onClose={fadeOutSnackbar}
    >
      <Copy size="m" text={label} />
      {action && (
        <StyledLink
          label={action.label}
          onClick={actionClicked}
          forwardedAs="button"
        />
      )}
    </StyledSnackbar>
  );
};
