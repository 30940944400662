import {
  type ApiConfig,
  type Environment,
  SearchApi,
} from '@ev/search-modules-api';

export const config: ApiConfig = {
  applicationKey: process.env.NEXT_PUBLIC_APPLICATION_KEY!,
  environment: (process.env.NEXT_PUBLIC_BFF_ENV ?? 'prod') as Environment,
  axiosConfig: {
    ...(process.env.NEXT_PUBLIC_USE_BFF_PROXY === 'true' && {
      baseURL: 'http://localhost:3000/api/bff-proxy',
    }),
  },
};

export const searchApi = new SearchApi(config);
