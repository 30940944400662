import { type ReactElement, type SVGProps } from 'react';

export const Info = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0012 8.27441C12.4154 8.27441 12.7512 7.93863 12.7512 7.52441C12.7512 7.1102 12.4154 6.77441 12.0012 6.77441C11.587 6.77441 11.2512 7.1102 11.2512 7.52441C11.2512 7.93863 11.587 8.27441 12.0012 8.27441Z"
        fill="currentColor"
      />
      <path d="M12.5012 10V16.9999H11.5012V10H12.5012Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
        fill="currentColor"
      />
    </svg>
  );
};
