import React, { type FC } from 'react';

import {
  StyledCloseIcon,
  StyledContainer,
  StyledDialog,
} from './Dialog.styles';

type ModalProps = {
  className?: string;
  onClose?: () => void;
  showCloseButton?: boolean;
  children?: React.ReactNode;
};

export const Dialog: FC<ModalProps> = ({
  className,
  onClose,
  showCloseButton,
  children,
}) => {
  return (
    <StyledDialog open onClose={onClose}>
      <StyledContainer className={className}>
        {children}
        {showCloseButton && (
          <StyledCloseIcon onClick={onClose} data-cy="dialogCloseButton" />
        )}
      </StyledContainer>
    </StyledDialog>
  );
};
