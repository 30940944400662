import { type ReactElement, type SVGProps } from 'react';

export const Warning = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4999 9.43848L12.4999 14L11.4999 14L11.4999 9.43848H12.4999Z"
        fill="currentColor"
      />
      <path
        d="M12.7493 16.3701C12.7493 16.7843 12.4135 17.1201 11.9993 17.1201C11.5851 17.1201 11.2493 16.7843 11.2493 16.3701C11.2493 15.9559 11.5851 15.6201 11.9993 15.6201C12.4135 15.6201 12.7493 15.9559 12.7493 16.3701Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0011 2.99902L21.9962 20.0004H2L12.0011 2.99902ZM3.74844 19.0004H20.2483L12.0009 4.97173L3.74844 19.0004Z"
        fill="currentColor"
      />
    </svg>
  );
};
