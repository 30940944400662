import { type Currency, type Language } from '@ev/search-modules-api';

import { fallbackLocale } from '../const/fallbackLocale';
import { formatNumber } from './numberFormatters';

const formatPrice = (
  number: number,
  language: Language = fallbackLocale,
  opts: Intl.NumberFormatOptions & { notation?: string } = {}
): string => {
  if (number <= 0) {
    return '';
  }
  return formatNumber(number, language, {
    style: 'currency',
    maximumFractionDigits: opts.maximumFractionDigits || 0,
    minimumFractionDigits: opts.minimumFractionDigits || 0,
    ...{ currency: 'EUR', ...opts },
  });
};

export const formatPriceWithoutDecimals = (
  price: number,
  language: Language = fallbackLocale,
  currency: string | Currency
) => {
  return formatPrice(price, language, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currency: currency,
  });
};

export const formatPriceWithDecimals = (
  price: number,
  language: Language = fallbackLocale,
  currency: string | Currency
) => {
  // we add this check to avoid trailing zero e.g 12.00€ when formatting prices without decimals
  const isInteger = price % 1 === 0;
  return formatPrice(price, language, {
    maximumFractionDigits: isInteger ? 0 : 2,
    minimumFractionDigits: isInteger ? 0 : 2,
    currency: currency,
  });
};
