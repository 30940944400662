import { CountryCode } from '@ev/search-modules-api';

export const EV_FINANCE_URL =
  'https://www.ev-finance.de/finanzierung?utm_source=engelvoelkers_website&utm_medium=online_expose&utm_id=1';

export const PROPERTY_SUBTYPE_PREFIX =
  'search.searchbar.advFilter.propertySubType.';

export const SEO_INDEXED_COUNTRIES: CountryCode[] = [
  CountryCode.DE,
  CountryCode.ES,
  CountryCode.PT,
  CountryCode.AE,
  CountryCode.FR,
  CountryCode.US,
  CountryCode.CA,
  CountryCode.BS,
  CountryCode.VG,
  CountryCode.TC,
  CountryCode.KY,
  CountryCode.CR,
  CountryCode.MX,
  CountryCode.MF,
  CountryCode.PA,
  CountryCode.BZ,
  CountryCode.GR,
  CountryCode.LU,
  CountryCode.MU,
  CountryCode.LI,
  CountryCode.CH,
  CountryCode.IE,
  CountryCode.HR,
  CountryCode.CO,
  CountryCode.CL,
  CountryCode.UY,
  CountryCode.ZA,
  CountryCode.CZ,
  CountryCode.AD,
  CountryCode.HU,
  CountryCode.BE,
  CountryCode.NL,
  CountryCode.DK,
  CountryCode.IT,
  CountryCode.AT,
  CountryCode.HK,
];

export const FINANCING_ENABLED_COUNTRIES: CountryCode[] = [
  CountryCode.DE,
  CountryCode.ES,
];
