import { type ReactElement, type SVGProps } from 'react';

export const CheckCircle = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2323 9.02545L10.2226 16.0352L6.77018 12.5828L7.47728 11.8757L10.2226 14.621L16.5252 8.31835L17.2323 9.02545Z"
        fill="currentColor"
      />
    </svg>
  );
};
