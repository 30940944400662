import { Box, styled, Typography } from '@mui/material';

import { Dialog } from '@/components/common/Dialog/Dialog';

export const StyledTitleContainer = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(26.25),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(4),
  alignItems: 'center',
}));

export const StyledMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(5.5, 4),
}));

export const StyledTitle = styled(Typography)(() => ({
  '&&': {
    fontSize: 20,
    textAlign: 'center',
  },
}));

export const StyledMessage = styled(Typography)(() => ({
  '&&': {
    textAlign: 'justify',
    fontSize: 14,
  },
}));

export const StyledBold = styled('span')(() => ({
  fontWeight: 'bold',
}));
