import { useContext } from 'react';

import {
  type SettingsContextType,
  SettingsContext,
} from '@/core/providers/SettingsProvider';

export const useSettings = (): SettingsContextType => {
  const ctx = useContext(SettingsContext);
  if (ctx === undefined) {
    throw new Error('useSettings used outside of ContextProvider!');
  } else {
    return ctx;
  }
};
