import { CountryCode } from '@ev/search-modules-api';

export const ENERGY_CLASSES = [
  'A+',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
] as const;

export const EU_COUNTRIES: (CountryCode | string)[] = [
  CountryCode.AT,
  CountryCode.BE,
  'BG',
  CountryCode.HR,
  'CY',
  CountryCode.CZ,
  CountryCode.DK,
  'EE',
  'FI',
  CountryCode.FR,
  CountryCode.DE,
  CountryCode.GR,
  CountryCode.HU,
  CountryCode.IE,
  CountryCode.IT,
  'LV',
  CountryCode.LT,
  CountryCode.LU,
  'MT',
  CountryCode.NL,
  CountryCode.PL,
  CountryCode.PT,
  'RO',
  'SK',
  'SI',
  CountryCode.ES,
  'SE',
];
