import { Trans, useTranslation } from 'next-i18next';
import type { ReactElement } from 'react';

import { InterrogationMarkIcon } from '@/components/common/Icons/InterrogationMarkIcon';

import {
  StyledBold,
  StyledDialog,
  StyledMessage,
  StyledMessageContainer,
  StyledTitle,
  StyledTitleContainer,
} from './BlockedLocalStorageDialog.styles';

export const BlockedLocalStorageDialog = ({
  onClose,
}: {
  onClose: () => void;
}): ReactElement | null => {
  const { t } = useTranslation();

  return (
    <StyledDialog showCloseButton onClose={onClose}>
      <StyledTitleContainer>
        <InterrogationMarkIcon />
        <StyledTitle> {t('search.blockedLocalStorage.title')}</StyledTitle>
      </StyledTitleContainer>
      <StyledMessageContainer>
        <StyledMessage>
          <Trans
            i18nKey="search.blockedLocalStorage.message0"
            components={[<StyledBold key={Math.random()} />]}
          />
        </StyledMessage>

        <StyledMessage>
          <Trans
            i18nKey="search.blockedLocalStorage.message1"
            components={[<StyledBold key={Math.random()} />]}
          />
        </StyledMessage>
      </StyledMessageContainer>
    </StyledDialog>
  );
};
