import {
  CountryCode,
  Currency,
  Language,
  MeasurementSystem,
} from '@ev/search-modules-api';
import { z } from 'zod';

import { type Settings } from '../providers/SettingsProvider';
import { UnitEnum } from '../types/store.types';

const SETTINGS_KEY = 'settings';

const settingsSchema = z.object({
  currency: z.string().toUpperCase().pipe(z.nativeEnum(Currency)).optional(),
  measurementSystem: z.nativeEnum(MeasurementSystem).optional(),
  language: z.nativeEnum(Language).optional(),
  unit: z.string().toUpperCase().pipe(z.nativeEnum(UnitEnum)).optional(),
  country: z.string().toUpperCase().pipe(z.nativeEnum(CountryCode)).optional(),
  countryCode: z
    .string()
    .toUpperCase()
    .pipe(z.nativeEnum(CountryCode))
    .optional(),
});

export const isLocalStorageAvailable = (): boolean => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const readSettingsFromStorage = (): Partial<Settings> | null => {
  const localSettings =
    isLocalStorageAvailable() && window.localStorage.getItem(SETTINGS_KEY);

  if (!localSettings) return null;

  const settings = JSON.parse(localSettings);
  const parsedSettings = settingsSchema.safeParse(settings);

  if (!parsedSettings.success) {
    console.warn(
      'Error parsing settings from local storage',
      parsedSettings.error.flatten().fieldErrors
    );
    return null;
  }

  const currency = parsedSettings.data.currency;
  let measurementSystem = parsedSettings.data.measurementSystem;
  if (parsedSettings.data.unit) {
    // some other teams use unit instead of measurementSystem as key in the local storage
    measurementSystem =
      parsedSettings.data.unit === UnitEnum.MT
        ? MeasurementSystem.metric
        : MeasurementSystem.imperial;
  }

  return {
    currency,
    measurementSystem,
  };
};

export const writeSettingsToStorage = (
  settings: Partial<Settings>,
  language: Language
) => {
  if (!isLocalStorageAvailable()) {
    console.warn('Local storage is not available');
    return;
  }

  // preserve any existing settings that we may not know about
  const currentSettings = window.localStorage.getItem(SETTINGS_KEY);
  const currentSettingsParsed = currentSettings
    ? JSON.parse(currentSettings)
    : {};

  window.localStorage.setItem(
    SETTINGS_KEY,
    JSON.stringify({
      ...currentSettingsParsed,
      ...settings,
      // some other teams use unit instead of measurementSystem as key in the local storage
      unit:
        settings.measurementSystem === MeasurementSystem.metric
          ? UnitEnum.MT
          : UnitEnum.FT,
      language,
    })
  );
};
