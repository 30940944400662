// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: [
      'en',
      'de',
      'fr',
      'it',
      'es',
      'el',
      'da',
      'pt',
      'cs',
      'ca',
      'ru',
      'nl',
      'hu',
    ],
  },
  fallbackLng: false,
  localePath: path.resolve('./public/locales'),
  react: {
    useSuspense: false,
  },
  debug: false,
};
