export enum Country {
  DE = 'DE',
  FR = 'FR',
  IT = 'IT',
  BE = 'BE',
  AT = 'AT',
  HK = 'HK',
  DK = 'DK',
  ZA = 'ZA',
  ES = 'ES',
  PT = 'PT',
  AE = 'AE',
  US = 'US', // United States
  CA = 'CA', // Canada
  BS = 'BS', // Bahamas
  VG = 'VG', // British-Virgin-Islands
  TC = 'TC', // Turks & Caicos (tc)
  KY = 'KY', //Cayman Islands (ky)
  CR = 'CR', // Costa Rica (cr)
  MX = 'MX', // Mexico (mx)
  MF = 'MF', // Saint Martin (mf)
  PA = 'PA', // Panama (pa)
  BZ = 'BZ', //Belize (bz)
  GR = 'GR', // Greece
  LU = 'LU', // Luxembourg (lu)
  MU = 'MU', // Mauritius (mu)
  LI = 'LI', // Liechtenstein (li)
  CH = 'CH', // Switzerland (ch)
  IE = 'IE', // Ireland (ie)
  HR = 'HR', // Croatia (hr)
  CO = 'CO', // Colombia (co)
  CL = 'CL', // Chile (cl)
  UY = 'UY', // Uruguay (uy)
  CZ = 'CZ', // Czechia (cz)
  AD = 'AD', // Andorra (ad)
  HU = 'HU', // Hungary (hu)
  NL = 'NL', // Netherlands (nl)
}
