export enum CurrencyNameEnum {
  AED = 'search.globalSettings.currencies.aedirham',
  CAD = 'search.globalSettings.currencies.canadiandollar',
  CHF = 'search.globalSettings.currencies.swissfranc',
  CLF = 'search.globalSettings.currencies.chileanunitaccount',
  COP = 'search.globalSettings.currencies.colombianpeso',
  CZK = 'search.globalSettings.currencies.czechkoruna',
  DKK = 'search.globalSettings.currencies.danishkrone',
  EUR = 'search.globalSettings.currencies.euro',
  GBP = 'search.globalSettings.currencies.poundsterling',
  HUF = 'search.globalSettings.currencies.hungarianforint',
  MUR = 'search.globalSettings.currencies.mauritianrupee',
  USD = 'search.globalSettings.currencies.unitedstatesdollar',
  ZAR = 'search.globalSettings.currencies.southafricanrand',
}

export enum UnitEnum {
  MT = 'SQMT',
  FT = 'SQFT',
}
