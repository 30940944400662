import styled, { css } from 'styled-components';

import { focusStyleDark, focusStyleLight } from '@/components/utils';

const variants = {
  primary: css`
    background: var(--color-interaction-bg);
    color: var(--color-interaction-text);

    ${focusStyleDark};
    @media (hover: hover) {
      &:hover {
        background: var(--color-interaction-hover);
      }
    }

    &:active {
      background: var(--color-interaction-active);
    }
  `,
  primaryAlt: css`
    background: var(--color-interaction-inverted-bg);
    color: var(--color-interaction-inverted-text);

    ${focusStyleLight};
    @media (hover: hover) {
      &:hover {
        background: var(--color-interaction-inverted-hover);
      }
    }

    &:active {
      background: var(--color-interaction-inverted-active);
    }
  `,
  secondary: css`
    border: 1px solid var(--color-outlined-button-border);
    background: var(--color-outlined-button-bg);
    color: var(--color-outlined-button-text);

    ${focusStyleDark};
    @media (hover: hover) {
      &:hover {
        border-color: var(--color-outlined-button-hover);
        background: var(--color-outlined-button-hover);
        color: var(--color-outlined-button-text-hover);
      }
    }

    &:active {
      border-color: var(--color-outlined-button-active);
      background: var(--color-outlined-button-active);
      color: var(--color-outlined-button-text-active);
    }
  `,
  secondaryAlt: css`
    border: 1px solid var(--color-outlined-button-inverted-border);
    background: var(--color-outlined-button-inverted-bg);
    color: var(--color-outlined-button-inverted-text);

    ${focusStyleLight};
    @media (hover: hover) {
      &:hover {
        border-color: var(--color-outlined-button-inverted-hover);
        background: var(--color-outlined-button-inverted-hover);
        color: var(--color-outlined-button-inverted-text-hover);
      }
    }

    &:active {
      border-color: var(--color-outlined-button-inverted-active);
      background: var(--color-outlined-button-inverted-active);
      color: var(--color-outlined-button-inverted-text-active);
    }
  `,
  search: css`
    background: var(--color-cta-bg);
    color: var(--color-interaction-text);

    ${focusStyleDark};
    @media (hover: hover) {
      &:hover {
        background: var(--color-cta-hover);
      }
    }

    &:active {
      background: var(--color-cta-active);
    }
  `,
  tertiary: css`
    background: var(--color-transparent-icon-button-bg);
    color: var(--color-transparent-icon-button-text);

    ${focusStyleDark};

    &:focus-visible {
      background: var(--color-transparent-icon-button-bg-focus);
    }
    @media (hover: hover) {
      &:hover {
        background: var(--color-transparent-icon-button-bg-hover);
      }
    }

    &:active {
      background: var(--color-transparent-icon-button-bg-active);
    }
  `,
  tertiaryAlt: css`
    background: var(--color-transparent-icon-button-alt-bg);
    color: var(--color-transparent-icon-button-alt-text);

    ${focusStyleDark};
    @media (hover: hover) {
      &:hover {
        background: var(--color-transparent-icon-button-alt-bg-hover);
      }
    }

    &:active {
      background: var(--color-transparent-icon-button-alt-bg-active);
    }
  `,
  tertiaryAltInverted: css`
    background: var(--color-transparent-icon-button-alt-inverted-bg);
    color: var(--color-transparent-icon-button-alt-inverted-text);

    ${focusStyleLight};
    @media (hover: hover) {
      &:hover {
        background: var(--color-transparent-icon-button-alt-inverted-bg-hover);
      }
    }

    &:active {
      background: var(--color-transparent-icon-button-alt-inverted-bg-active);
    }
  `,
  transparent: css`
    background: var(--color-transparent-icon-button-bg);
    color: var(--color-transparent-icon-button-text);

    ${focusStyleDark};

    &:focus-visible {
      background: var(--color-transparent-icon-button-bg);
    }
    @media (hover: hover) {
      &:hover {
        background: var(--color-transparent-icon-button-hover);
      }
    }

    &:active {
      background: var(--color-transparent-icon-button-active);
    }
  `,
} as const;
export type IconButtonVariant = keyof typeof variants;

const sizes = {
  xsmall: css`
    height: var(--icon-size-xs);
    width: var(--icon-size-xs);
  `,
  small: css`
    width: var(--icon-size-s);
    height: var(--icon-size-s);
  `,
  medium: css`
    width: var(--icon-size-m);
    height: var(--icon-size-m);
  `,
};
export type IconButtonSizes = keyof typeof sizes;

const shapes = {
  square: css`
    border-radius: var(--border-radius-m);
  `,
  circle: css`
    border-radius: var(--border-radius-circle);
  `,
};
export type IconButtonShapes = keyof typeof shapes;

interface IStyledIconButtonProps {
  $variant: IconButtonVariant;
  $size: IconButtonSizes;
  $shape: IconButtonShapes;
  as?: 'button' | 'a';
}

export const StyledIconButton = styled.a<
  IStyledIconButtonProps & { disabled?: boolean }
>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0;

  ${({ as }) =>
    as === 'a' &&
    css`
      text-decoration: none;
    `}

  ${({ $size }) => sizes[$size]}
  ${({ $shape }) => shapes[$shape]}

  ${({ $variant }) => variants[$variant]}

  &:focus-visible {
    &::after {
      ${({ $shape }) => shapes[$shape]}
    }
  }

  &:disabled {
    background: var(--color-interaction-disabled-bg);
    color: var(--color-interaction-disabled-text);
    border-color: var(--color-interaction-disabled-bg);
    box-shadow: none;
  }
`;
