import React from 'react';

import { CheckCircle } from '@/icons/small/CheckCircle';
import { CrossNaked } from '@/icons/small/CrossNaked';
import { Info } from '@/icons/small/Info';
import { Warning } from '@/icons/small/Warning';

import {
  StyledAlert,
  StyledCloseButton,
  StyledContainer,
  StyledDescription,
  StyledDialog,
  StyledHeadline,
  StyledIcon,
} from './Alert.styled';

const alertVariants = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
} as const;

export type AlertVariants = keyof typeof alertVariants;

interface IAlertProps {
  className?: string;
  variant: AlertVariants;
  title?: string;
  onClose?: () => void;
  children: React.ReactNode;
  animation?: boolean;
}

const alertIcons = {
  [alertVariants.success]: <CheckCircle />,
  [alertVariants.warning]: <Warning />,
  [alertVariants.error]: <Info />,
  [alertVariants.info]: <Info />,
};

export const Alert = ({
  className,
  title,
  children,
  onClose,
  variant,
  animation,
}: IAlertProps) => {
  const critical = (['warning', 'error'] as AlertVariants[]).includes(variant);

  return (
    <StyledAlert
      className={className}
      $animation={animation}
      $variant={variant}
      role={critical ? 'alert' : 'status'}
      aria-labelledby={title && `${variant}-alert-title`}
      aria-describedby={`${variant}-alert-description`}
    >
      {onClose && (
        <StyledCloseButton
          icon={<CrossNaked aria-hidden="true" />}
          variant="transparent"
          onClick={onClose}
        />
      )}
      <StyledDialog>
        {title && (
          <StyledContainer>
            <StyledIcon $variant={variant} aria-hidden="true">
              {alertIcons[variant]}
            </StyledIcon>
            <StyledHeadline
              text={title}
              size="m"
              bold
              id={`${variant}-alert-title`}
            />
          </StyledContainer>
        )}
        <StyledDescription
          id={`${variant}-alert-description`}
          $hasTitle={!!title}
        >
          {!title && (
            <StyledIcon $variant={variant} aria-hidden="true">
              {alertIcons[variant]}
            </StyledIcon>
          )}
          {children}
        </StyledDescription>
      </StyledDialog>
    </StyledAlert>
  );
};
