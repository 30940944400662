import { type Language } from '@ev/search-modules-api';

import { fallbackLocale } from '@/core/const/fallbackLocale';

export const formatNumber = (
  number: Maybe<number>,
  locale: Language = fallbackLocale,
  opts: Intl.NumberFormatOptions & { notation?: string } = {}
): string => {
  if (!number) {
    return '';
  }

  // The formatted numbers in french language are using Narrow no break space https://www.codetable.net/decimal/8239 as thousands separator
  // and the E&V font is rendering this kind of white spaces bigger than usual white spaces like &nbsp;
  // here we are replacing all white spaces with a blank space character as a workaround
  // https://engelvoelkers.atlassian.net/browse/NS-3288?focusedCommentId=342373
  return new Intl.NumberFormat(locale, { ...opts })
    .format(number)
    .replace(/\s+/g, ' ');
};

export const getNumberUnformatted = (value: string): string => {
  // remove space, comma or period from a string
  return value.replace(/,|\.|\s/g, '');
};
