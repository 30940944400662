import styled, { css, keyframes } from 'styled-components';

import { Copy } from '../Copy';
import { IconButton } from '../IconButton';
import { type AlertVariants } from './Alert';

const bounceInZoomOut = keyframes`
	0% {
		transform:scale(0);
		animation-timing-function:ease-in;
		opacity:0;
	}
	35% {
		transform:scale(1);
		animation-timing-function:ease-out;
		opacity:1;
	}
	55% {
		transform:scale(.7);
		animation-timing-function:ease-in;
	}
	70% {
		transform:scale(1);
		animation-timing-function:ease-out;
	}
	100% {
		transform:scale(1);
		animation-timing-function:ease-in;
	}
`;

const alertBackground = {
  success: css`
    background: var(--color-alert-success);
  `,
  warning: css`
    background: var(--color-alert-warning);
  `,
  error: css`
    background: var(--color-alert-error);
  `,
  info: css`
    background: var(--color-alert-info);
  `,
};

const alertIconColor = {
  success: css`
    color: var(--color-alert-icon-success);
  `,
  warning: css`
    color: var(--color-alert-icon-warning);
  `,
  error: css`
    color: var(--color-alert-icon-error);
  `,
  info: css`
    color: var(--color-alert-icon-info);
  `,
};

export const StyledCloseButton = styled(IconButton)`
  margin-left: var(--distance-static-s);
`;

export const StyledHeadline = styled(Copy)`
  color: var(--color-text-dark-highlight);
`;

export const StyledDialog = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  outline: none;
`;

export const StyledIcon = styled.div<{
  $variant: AlertVariants;
}>`
  margin-right: var(--distance-static-xs);
  width: var(--distance-static-l);
  height: var(--distance-static-l);

  ${({ $variant }) => alertIconColor[$variant]}
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--distance-static-xs);
`;

export const StyledDescription = styled.div<{ $hasTitle: boolean }>`
  display: flex;
  ${({ $hasTitle }) =>
    $hasTitle &&
    css`
      margin-left: var(--distance-static-xl);
    `}
`;

export const StyledAlert = styled.div<{
  $variant: AlertVariants;
  $animation?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  box-sizing: border-box;
  padding: var(--distance-static-m);
  margin-bottom: var(--distance-static-xs);
  width: 100%;
  ${({ $variant }) => alertBackground[$variant]}

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 100%;
    height: var(--distance-static-xs);
  }

  ${({ $animation }) =>
    $animation &&
    css`
      ${StyledIcon} {
        animation: ${bounceInZoomOut} 1s linear 0s 1 normal none;
      }
    `};
`;
