export enum PageTypeEnum {
  WATCHLIST = 'watchlist-page',
  SEARCH_RESULT = 'search-result-page',
  LIPA_SEARCH_RESULT = 'lipa-search-result-page',
  FLYOUT = 'flyout',
  EXPOSE = 'expose-page',
  PROJECT = 'project-page',
  DEVELOPMENT_EXPOSE = 'development-expose-page',
  NOT_FOUND = '404-page',
  DEVELOPMENT_EXPOSE_UNITS_GRID = 'development-expose-page-units-grid',
  DEVELOPMENT_EXPOSE_UNITS_LIST = 'development-expose-page-units-list',
}

export type TrackingProvider =
  | 'licencePartnerCriteoID'
  | 'licencePartnerFacebookID'
  | 'licencePartnerGA4ID'
  | 'licencePartnerLinkedInID'
  | 'licencePartnerMicrosoftID'
  | 'licencePartnerGoogleAdsID'
  | 'marketCriteoID'
  | 'marketFacebookID'
  | 'marketGA4ID'
  | 'marketLinkedInID'
  | 'marketMicrosoftID'
  | 'marketGoogleAdsID'
  | 'globalCriteoID'
  | 'globalFacebookID'
  | 'globalGA4ID'
  | 'globalLinkedInID'
  | 'globalMicrosoftID'
  | 'globalGoogleAdsID';

export type TrackingIDs = {
  [key in TrackingProvider]?: string | number | null;
};

export type LocalStorageTrackingProperty = {
  [key: string]: TrackingIDs;
};

export type ABTestVariation = 'A' | 'B';
