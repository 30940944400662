import { viewportSizes } from '@/components/utils';

export const from = {
  tabletPortrait: `min-width: ${viewportSizes.TABLET_PORTRAIT}px`,
  tabletLandscape: `min-width: ${viewportSizes.TABLET_LANDSCAPE}px`,
  laptop: `min-width: ${viewportSizes.LAPTOP}px`,
  desktop: `min-width: ${viewportSizes.DESKTOP}px`,
};

export const to = {
  tabletPortrait: `max-width: ${viewportSizes.TABLET_PORTRAIT - 1}px`,
  tabletLandscape: `max-width: ${viewportSizes.TABLET_LANDSCAPE - 1}px`,
  laptop: `max-width: ${viewportSizes.LAPTOP - 1}px`,
  desktop: `max-width: ${viewportSizes.DESKTOP - 1}px`,
};

export const getPrefersReducedMotion = () => {
  const motionQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
  return motionQuery.matches;
};
