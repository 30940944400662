import React, { useState } from 'react';

import {
  type SnackbarProps,
  Snackbar,
} from '@/components/redesign/modules/Snackbar/Snackbar';

type SnackbarPayload = Omit<SnackbarProps, 'onClose'>;

type SnackbarContextType = {
  snackbar: (props: SnackbarPayload) => void;
  clearSnackbars: () => void;
};

const initialStore: SnackbarContextType = {
  snackbar: () => null,
  clearSnackbars: () => null,
};

export const SnackbarContext =
  React.createContext<SnackbarContextType>(initialStore);

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [openSnackbars, setOpenSnackbars] = useState<
    (SnackbarPayload & { id: symbol })[]
  >([]);

  const snackbar = (props: SnackbarPayload) => {
    const id = Symbol(Date.now()); // adding Date.now to be able to use symbol id as react key
    setOpenSnackbars((prev) => [...prev, { ...props, id }]);
  };

  const handleClose = (id: symbol) => {
    setOpenSnackbars((prev) => prev.filter((snackbar) => snackbar.id !== id));
  };

  const clearSnackbars = () => setOpenSnackbars([]);

  return (
    <SnackbarContext.Provider value={{ snackbar, clearSnackbars }}>
      {openSnackbars.map((props) => (
        <Snackbar
          key={props.id.toString()}
          {...props}
          onClose={() => handleClose(props.id)}
        />
      ))}
      {children}
    </SnackbarContext.Provider>
  );
};
