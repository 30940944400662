export const AB_TESTS = {
  contactForm: {
    id: 'new-search-contact-form-ab-test',
    name: 'new-search-contact-form-ab-test',
    cookie: {
      name: 'new-search-ab-test',
      duration: 31536000,
    },
    variants: {
      A: 'contact form with address fields',
      B: 'contact form without address fields',
    },
  },
};
