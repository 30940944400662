export const viewportSizes = {
  MOBILE: 0,
  TABLET_PORTRAIT: 600,
  TABLET_LANDSCAPE: 905,
  LAPTOP: 1240,
  DESKTOP: 1440,
} as const;
export type ViewportSize = keyof typeof viewportSizes;

export const mobile = `max-width: ${viewportSizes.TABLET_PORTRAIT - 1}px`;
export const tabletPortrait = `min-width: ${viewportSizes.TABLET_PORTRAIT}px`;
export const tabletLandscape = `min-width: ${viewportSizes.TABLET_LANDSCAPE}px`;
export const maxTabletLandscape = `max-width: ${
  viewportSizes.TABLET_LANDSCAPE - 1
}px`;
export const maxTabletPortrait = `max-width: ${viewportSizes.TABLET_PORTRAIT}px`;
export const laptop = `min-width: ${viewportSizes.LAPTOP}px`;
export const desktop = `min-width: ${viewportSizes.DESKTOP}px`;
